import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { IoBagHandleOutline } from "react-icons/io5";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { backend_url } from "../../server";
import { useDispatch, useSelector } from "react-redux";
import { addToInventoryCart, removeFromInventoryCart } from "../../redux/actions/inventoryCart";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import ReactStepper from "../Stepper/UserWithdrawStepper";

const Cart = ({ setOpenCart }) => {
  const { inventoryCart } = useSelector((state) => state.inventoryCart);
  const [showStepper, setShowStepper] = useState(false);
  const dispatch = useDispatch();

  const removeFromInventoryCartHandler = (data) => {
    dispatch(removeFromInventoryCart(data));
  };

  const totalPrice = inventoryCart.reduce(
    (acc, item) => acc + item.qty * item.originalPrice,
    0
  );

  const quantityChangeHandler = (data) => {
    dispatch(addToInventoryCart(data));
  };

  const steps = [
    { title: "Inventory Withdrawal" },
    { title: "This is your bot" },
    { title: "Are you level 10?" },
    { title: "Join the server" },
  ];

  return (
    <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
    <div className="fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-10">
      <div className="fixed top-0 right-0 h-full w-[80%] 800px:w-[25%] bg-white flex flex-col overflow-y-scroll justify-between shadow-sm">
        {inventoryCart && inventoryCart.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end pt-5 pr-5 fixed top-3 right-3">
               <RxCross1 
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenCart(false)}
                />
            </div>
            <h5 class="baloo">Cart is empty!</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full justify-end pt-5 pr-5 baloo">
                <RxCross1
                  size={25}
                  className="cursor-pointer"
                  onClick={() => setOpenCart(false)}
                />
              </div>
              {/* Item length */}
              <div className={`${styles.normalFlex} p-4`}>
                <IoBagHandleOutline size={25} />
                <h5 className="pl-2 text-[20px] font-[500] baloo">{inventoryCart && inventoryCart.length} items to withdraw</h5>
              </div>

              {/* cart Single Items */}
              <br />
              <div className="w-full border-t">
                {inventoryCart &&
                  inventoryCart.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      quantityChangeHandler={quantityChangeHandler}
                      removeFromInventoryCartHandler={removeFromInventoryCartHandler}
                    />
                  ))}
              </div>
            </div>

            <div className="px-5 mb-3" onClick={() => setShowStepper(true)}>
              {/* withdraw buttons */}
                <div
                  className={`h-[45px] flex items-center justify-center w-[100%] bg-[#5ec3e0] rounded-[5px]`}
                >
                  <h1 className="text-[#fff] text-[18px] font-[600] baloo">
                    Withdraw Now
                  </h1>
                </div>
            </div>

            {showStepper && (
      <ReactStepper steps={steps} onClose={() => setShowStepper(false)} />
    )}

          </>
        )}
      </div>
    </div>
    </CSSTransition>
  );
};

const CartSingle = ({ data, quantityChangeHandler, removeFromInventoryCartHandler }) => {
  const [value, setValue] = useState(data.qty);
  const totalPrice = data.originalPrice;

  const increment = (data) => {
   // if (data.stock < value) {
      toast.error("Product stock limited!");
   // } else {
    //  setValue(value + 1);
    //  const updateCartData = { ...data, qty: value + 1 };
    //  quantityChangeHandler(updateCartData);
   // }
  };

  const decrement = (data) => {
    setValue(value === 1 ? 1 : value - 1);
    const updateCartData = { ...data, qty: value === 1 ? 1 : value - 1 };
    quantityChangeHandler(updateCartData);
  };

  return (
    <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
    <div className="border-b p-4">
      <div className="w-full flex items-center">
        <img
          src={`${data?.images[0]}`}
          alt=""
          className="w-[130px] h-min ml-2 mr-2 rounded-[5px]"
        />
        <div className="pl-[5px]">
          <h1 className="baloo">{data.name}</h1>
          <h4 className="font-[400] text-[15px] text-[#00000082] baloo">
            ${data.originalPrice} 
          </h4>
          <h4 className="font-[600] text-[17px] pt-[3px] text-[#5ec3e0] baloo">
            US${totalPrice}
          </h4>
        </div>
        <RxCross1
          className="cursor-pointer"
          onClick={() => removeFromInventoryCartHandler(data)}
        />
      </div>
    </div>
    </CSSTransition>
  );
};

export default Cart;
