// navigation Data
export const navItems = [
    {}
  ];
  
  // branding data
  export const brandingData = [
    {
      id: 1,
      title: "Instant Delivery",
      Description: "All orders delivered immediately after your order is confirmed with our automated system",
      icon: (
        <svg width="32" height="34" aria-hidden="true" focusable="false" role="presentation" class="icon icon-truck" viewBox="0 0 24 20" ><path  d="M16 0H1a1 1 0 00-1 1v13a1 1 0 001 1h15a1 1 0 001-1V1a1 1 0 00-1-1zm-1 2v11H2V2h13z"></path><path d="M20 5h-4a1 1 0 00-1 1v8a1 1 0 001 1h7a1 1 0 001-1V9a1 1 0 00-.293-.707l-3-3A1 1 0 0020 5zm-.415 2L22 9.415V13h-5V7h2.585zM5.5 13a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm0 2a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"></path><path d="M18.5 13a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm0 2a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"></path></svg>
      ),
    },
    {
      id: 2,
      title: "New Stock Everyday",
      Description: "Our traders are always getting new pets to make sure we have what you want",
      icon: (
        <svg width="32" height="34" aria-hidden="true" focusable="false" role="presentation" class="icon icon-refresh-ccw" viewBox="0 0 24 20"><path d="M1 1a1 1 0 01.993.883L2 2v5h5a1 1 0 01.993.883L8 8a1 1 0 01-.883.993L7 9H1a1 1 0 01-.993-.883L0 8V2a1 1 0 011-1zM23 11a1 1 0 01.993.883L24 12v6a1 1 0 01-1.993.117L22 18v-5h-5a1 1 0 01-.993-.883L16 12a1 1 0 01.883-.993L17 11h6z"></path><path d="M23.729 11.315a1 1 0 01.042 1.322l-.086.092-4.618 4.338a10 10 0 01-16.5-3.733 1 1 0 011.886-.668 8 8 0 005.776 5.134 8.01 8.01 0 007.233-1.963l.213-.206 4.64-4.36a1 1 0 011.414.044zM14.212.25a10 10 0 017.22 6.417 1 1 0 01-1.885.668A8 8 0 0013.771 2.2a8.01 8.01 0 00-7.233 1.963l-.213.206-4.64 4.36A1 1 0 01.229 7.363l.086-.092 4.618-4.338A10 10 0 0114.213.25z"></path></svg>
      ),
    },
    {
      id: 3,
      title: "Kind Staff",
      Description: "Need help? Our staff members are always here to help - <strong> support@tradeblox.gg </strong>",
      icon: (
        <svg width="32" height="34" aria-hidden="true" focusable="false" role="presentation" class="icon icon-heart" viewBox="0 0 24 21"><path d="M16.663.004a6.5 6.5 0 00-4.31 1.9L12 2.254l-.353-.352a6.501 6.501 0 00-9.194 9.194l8.84 8.84a1 1 0 001.414 0l8.84-8.84A6.5 6.5 0 0016.95-.002l-.287.006zm.287 1.994A4.5 4.5 0 0121.452 6.5l-.006.238a4.5 4.5 0 01-1.313 2.945L12 17.815 3.867 9.683a4.501 4.501 0 016.366-6.366l1.06 1.06a1 1 0 001.414 0l1.06-1.06a4.5 4.5 0 013.183-1.319z"></path></svg>
      ),
    },
  ];
  
  // categories data
  export const categoriesData = [
    {
      id: 1,
      title: "Adopt Me",
      subTitle: "",
      image_Url:
        "/images/games/amp.png",
    },
    {
      id: 2,
      title: "Murder Mystery 2",
      subTitle: "",
      image_Url:
        "/images/games/mm2.png/",
    },
    {
      id: 3,
      title: "Pet Simulator",
      subTitle: "",
      image_Url:
        "/images/games/petsim.png",
    },
  ];
  
  // product Data
  export const productData = [
    {
      id: 1,
      category:"Computers and Laptops",
      name: "MacBook pro M2 chipset 256gb ssd 8gb ram space-gray color with apple 1 year warranty",
      description:
        "Product details are a crucial part of any eCommerce website or online marketplace. These details help the potential customers to make an informed decision about the product they are interested in buying. A well-written product description can also be a powerful marketing tool that can help to increase sales.Product details typically include information about the product's features, specifications, dimensions, weight, materials, and other relevant information that can help customers to understand the product better. The product details section should also include high-quality images and videos of the product, as well as customer reviews and ratings.",
      image_Url: [
        {
          public_id: "test",
          url: "https://www.istorebangladesh.com/images/thumbs/0000286_macbook-pro-m1_550.png",
        },
        {
          public_id: "test",
          url: "https://www.istorebangladesh.com/images/thumbs/0000286_macbook-pro-m1_550.png",
        },
      ],
      shop: {
        name: "Apple inc.",
        shop_avatar: {
          public_id: "test",
          url: "https://www.hatchwise.com/wp-content/uploads/2022/05/amazon-logo-1024x683.png",
        },
        ratings: 4.2,
      },
      price: 1099,
      discount_price: 1049,
      rating: 4,
      total_sell: 35,
      stock: 10,
    },
  ];
  
  export const footerProductLinks = [
    {
      name: "About us",
      link: "/"
    },
    {
      name: "Our Blog",
    },
    {
      name: "Become a sponsor",
      link: "https://discord.com/invite/tradeblox"
    },
  ];
  
  export const footercompanyLinks = [
{
      name: "Murder Mystery 2",
      link: "/products?category=mm2"
    },
    {
      name: "Pet Simulator 99",
    },
  ];
  
  export const footerSupportLinks = [
    {
      name: "FAQ",
      link: "/faq",
    },
    {
      name: "Reviews",
      link: "https://www.trustpilot.com/review/tradeblox.gg"
    },
    {
      name: "Contact Us",
      link: "mailto:support@tradeblox.gg",
    },
  ];
  
