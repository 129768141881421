import React, { useState, useEffect} from "react";
import { RxCross1 } from "react-icons/rx";
import { IoBagHandleOutline } from "react-icons/io5";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { backend_url } from "../../server";
import { useDispatch, useSelector } from "react-redux";
import { addTocart, removeFromCart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import axios from "axios";

const Cart = ({ setOpenCart }) => {
  const [orderData, setOrderData] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const paymentUrl = "https://www.checkout.tradeblox.gg";

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const totalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.originalPrice,
    0
  );

  const shipping = totalPrice * 0.1;

  const orderInfo = {
    cart,
    totalPrice,
    shipping,
    user,
  }

  localStorage.setItem("latestOrder", JSON.stringify(orderInfo));

  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("latestOrder"));
    setOrderData(orderData);
  }, []);


  const order = {
    cart: orderData?.cart,
    user: user && user,
    totalPrice: Math.round(orderData?.totalPrice * 100),
    paymentInfo: user,
  };

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };
  
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const handlePayment = async (e) => {
    e.preventDefault();
    try {
      await axios
      .post(`${paymentUrl}/api/create-checkout-session`, order, config)
      .then((res) => {
        toast.warn("Redirecting...");
        localStorage.setItem("cartItems", JSON.stringify([]));
        localStorage.setItem("latestOrder", JSON.stringify([]));
        window.location.href = res.data.url;
      });
    } catch(error) {
      console.log(error);
    }
  }

  return (
    <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
    <div className="fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-10">
      <div className="fixed top-0 right-0 h-full w-[80%] 800px:w-[25%] bg-white flex flex-col overflow-y-scroll justify-between shadow-sm">
        {cart && cart.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end pt-5 pr-5 fixed top-3 right-3">
               <RxCross1 
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenCart(false)}
                />
            </div>
            <h5 class="baloo">Cart is empty!</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full justify-end pt-5 pr-5 baloo">
                <RxCross1
                  size={25}
                  className="cursor-pointer"
                  onClick={() => setOpenCart(false)}
                />
              </div>
              {/* Item length */}
              <div className={`${styles.normalFlex} p-4`}>
                <IoBagHandleOutline size={25} />
                <h5 className="pl-2 text-[20px] font-[500] baloo">{cart && cart.length} items</h5>
              </div>

              {/* cart Single Items */}
              <br />
              <div className="w-full border-t">
                {cart &&
                  cart.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      quantityChangeHandler={quantityChangeHandler}
                      removeFromCartHandler={removeFromCartHandler}
                    />
                  ))}
              </div>
            </div>

            <div className="px-5 mb-3">
              {/* checkout buttons */}
                <div
                  onClick={handlePayment}
                  className={`h-[45px] flex items-center justify-center w-[100%] bg-[#5ec3e0] rounded-[5px] cursor-pointer`}
                >
                  <h1 className="text-[#fff] text-[18px] font-[600] baloo">
                    Checkout Now (${totalPrice})
                  </h1>
                </div>
            </div>
          </>
        )}
      </div>
    </div>
    </CSSTransition>
  );
};

const CartSingle = ({ data, quantityChangeHandler, removeFromCartHandler }) => {
  const [value, setValue] = useState(data.qty);
  const totalPrice = data.originalPrice;

  const increment = (data) => {
   // if (data.stock < value) {
      toast.error("Product stock limited!");
   // } else {
    //  setValue(value + 1);
    //  const updateCartData = { ...data, qty: value + 1 };
    //  quantityChangeHandler(updateCartData);
   // }
  };

  const decrement = (data) => {
    setValue(value === 1 ? 1 : value - 1);
    const updateCartData = { ...data, qty: value === 1 ? 1 : value - 1 };
    quantityChangeHandler(updateCartData);
  };

  return (
    <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
    <div className="border-b p-4">
      <div className="w-full flex items-center">
        <div>
          <div
            className={`bg-[#5ec3e0] rounded-full w-[25px] h-[25px] ${styles.normalFlex} justify-center cursor-pointer`}
            onClick={() => increment(data)}
          >
            <HiPlus size={18} color="#fff" />
          </div>
          <span className="pl-[10px]">{data.qty}</span>
          <div
            className="bg-[#a7abb14f] rounded-full w-[25px] h-[25px] flex items-center justify-center cursor-pointer"
            onClick={() => decrement(data)}
          >
            <HiOutlineMinus size={16} color="#7d879c" />
          </div>
        </div>
        <img
          src={`${data?.images[0]}`}
          alt=""
          className="w-[130px] h-min ml-2 mr-2 rounded-[5px]"
        />
        <div className="pl-[5px]">
          <h1 className="baloo">{data.name}</h1>
          <h4 className="font-[400] text-[15px] text-[#00000082] baloo">
            ${data.originalPrice} 
          </h4>
          <h4 className="font-[600] text-[17px] pt-[3px] text-[#5ec3e0] baloo">
            US${totalPrice}
          </h4>
        </div>
        <RxCross1
          className="cursor-pointer"
          onClick={() => removeFromCartHandler(data)}
        />
      </div>
    </div>
    </CSSTransition>
  );
};

export default Cart;
