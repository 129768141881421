import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Lottie from "react-lottie";
import animationData from "../Assests/animations/107043-success.json";
import { useNavigate } from "react-router-dom";

const OrderSuccessPage = () => {
  return (
    <div>
      <Header />
      <Success />
      <Footer />
    </div>
  );
};

const Success = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center">
      <Lottie options={defaultOptions} width={300} height={300} />
      <h5 className="text-center text-[25px] text-[#000000a1] baloo">
        Your order was successful 🎉
      </h5>
      <div
        className="mb-24 text-white w-[150px] bg-[#2f9bff] h-[50px] font-semibold my-3 flex items-center justify-center rounded-full baloo cursor-pointer"
        onClick={() => navigate("/profile")}
      >
        Go to inventory
      </div>
      <br />
      <br />
    </div>
  );
};

export default OrderSuccessPage;
