import React from "react";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";
import DashboardHero from "../../components/Shop/DashboardHero";
import Footer from "../../components/Layout/Footer"
import { motion, AnimatePresence } from "framer-motion";

const ShopDashboardPage = () => {
  return (
        <div>
          <DashboardHeader />
          <AnimatePresence>  
          <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
          <div className="flex items-start justify-between w-full bg-gray-50">
            <div className="w-[80px] 800px:w-[330px]">
              <DashboardSideBar active={1} />
            </div>  
            <DashboardHero />
          </div>
          </motion.div>
          </AnimatePresence>
          <Footer />
        </div>
  );
};

export default ShopDashboardPage;
