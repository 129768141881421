import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { backend_url } from "../../server";
import ReactStepper from "../Stepper/DepositStepper";
import { motion, AnimatePresence } from "framer-motion";
import { IoIosSearch } from "react-icons/io";
import InventoryCard from "../Route/InventoryCard/InventoryCard";

const CreateProduct = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const { seller } = useSelector((state) => state.seller);
  const [showStepper, setShowStepper] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasFetchedData, setHasFetchedData] = useState(false); // Flag to track initial data fetch
  const [isFetchingMoreData, setIsFetchingMoreData] = useState(false);
  const loader = useRef(null);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredInventoryData = inventoryData.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const steps = [
    { title: "Inventory Deposit" },
    { title: "This is your bot" },
    {title: "Are you level 10?"},
    { title: "Join the server" },
  ];

  const fetchMoreData = async () => {
    try {
      setIsFetchingMoreData(true);
      const currentPage = Math.ceil(inventoryData.length / 10) + 1; // Assuming 10 items per page
      const response = await axios.get(
        backend_url +
          `api/v2/bot/get-shop-inventory/?username=${seller.email}&page=${currentPage}`
      );
      const { success, data } = response.data;

      if (success) {
        if (data.length > 0) {
          setInventoryData((prevData) => [...prevData, ...data]);
        } else {
          // No more data available, you can handle this case (e.g., disable infinite scroll)
        }
      } else {
        console.error("Failed to fetch additional inventory data");
      }
    } catch (error) {
      console.error("Error fetching additional inventory data:", error);
    } finally {
      setIsFetchingMoreData(false);
    }
  };

  const fetchInventoryData = async () => {
    try {
      const response = await axios.get(
        backend_url +
          `api/v2/bot/get-shop-inventory/?email=${seller.email}`
      );
      const { success, data } = response.data;

      if (success) {
        setInventoryData(data);
      } else {
        console.error("Failed to fetch user's inventory data");
      }
    } catch (error) {
      console.error("Error fetching user's inventory data:", error);
    } finally {
      setIsLoading(false); // Set loading to false after fetching data
    }
  };

  const handleObserver = async (entries) => {
    const target = entries[0];
    if (
      target.isIntersecting &&
      !isLoading &&
      hasFetchedData &&
      !isFetchingMoreData
    ) {
      await fetchMoreData();
    }
  };

  useEffect(() => {
    // Fetch initial inventory data on page load
    fetchInventoryData();
    setHasFetchedData(true); // Set the flag indicating initial data has been fetched
  }, [seller.robloxUsername]);

  useEffect(() => {
    // Setup Intersection Observer
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [loader, seller.robloxUsername]);

  return (
    <div className="w-full p-8 bg-gray-50">
  <h3 className="text-[28px] pb-2 baloo font-semibold text-gray-700">
    Inventory
  </h3>

  <div className="mb-4 relative">
    <div className="absolute inset-y-0 left-0 flex items-center pl-2">
      <IoIosSearch className="text-gray-500" size={20} />
    </div>
    <input
      type="text"
      placeholder="Search..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className="pl-8 p-2 border border-gray-300 rounded-md w-[400px] h-[45px] baloo text-lg"
    />
  </div>

  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-6 mb-6">
  <button
  onClick={() => setShowStepper(true)}
  className="col-span-full md:col-span-1 lg:col-span-2 xl:col-span-1 border-dashed border-2 border-gray-300 p-4 rounded-md py-16 px-4 text-gray-500 flex flex-col items-center justify-center transition duration-300 hover:border-gray-500 focus:outline-none"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    className="w-12 h-12 mx-auto mb-2"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>
  <span className="baloo font-semibold text-xl text-gray-500 text-center">ADD</span>
</button>

    {showStepper && (
      <ReactStepper steps={steps} onClose={() => setShowStepper(false)} />
    )}

    <AnimatePresence>
      {inventoryData &&
        filteredInventoryData.map((item) => (
          <motion.div
            key={item.id} // Assuming "id" is a unique identifier in your data
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5, delay: item.id * 0.1 }}
            className="col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1"
          >
            <InventoryCard data={item} />
          </motion.div>
        ))}
    </AnimatePresence>
  </div>

  <div ref={loader} />
</div>
  );
};

export default CreateProduct;
