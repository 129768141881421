import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { backend_url } from '../../server'
import axios from 'axios';
import Popup from 'reactjs-popup';

const Stepper = ({ steps, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [username, setUsername] = useState("");
  const [isUsernameEntered, setIsUsernameEntered] = useState(false);
  const [botInfo, setBotInfo] = useState("")
  const { isUser, user } = useSelector((state) => state.user);

  useEffect(() => {
    setAnimate(true);
  }, []); // Run the animation on component mount

  const handleUsernameChange = (e) => {
    const enteredUsername = e.target.value;
    setUsername(enteredUsername);
    setIsUsernameEntered(enteredUsername.trim() !== ''); // Set isUsernameEntered based on whether username is not empty
  };

  const validateUsername = async (robloxUsername) => {
    try {
      const response = await axios.post(`${backend_url}api/v2/user/validate-username`, { username: robloxUsername });
      const isValid = response.data.data;

      return isValid;
    } catch (error) {
      console.error('Error validating username:', error);

      // Return false in case of an error
      return false;
    }
  };

  const getbotInfo = async () => {
    try {
      const response = await axios.get(`${backend_url}api/v2/bot/get-bots`);
      setBotInfo(response.data.bot); 
  
    } catch (error) {
      console.error(error);
      toast.error("Internal Server Error!");
      onClose();
    }
  };

  const createWithdrawRequest = async () => {
     try {
      const data = {
        username: username,
        type: "mm2",
        request: "withdraw",
        email: user.email,
        items: JSON.parse(localStorage.getItem("inventoryCartItems"))
      }
      await axios.post(`${backend_url}api/v2/bot/create-withdraw-request`, data)
     } catch(error) {
       console.log(error);
       toast.error("You already have a pending request or cannot withdraw these items!");
       window.open(botInfo.botPrivateServerUrl, '_blank');
       localStorage.removeItem("inventoryCartItems");
       window.location.reload();
       onClose();
     }
  }

  const nextStep = async () => {
    console.log(currentStep)
    if (isStep1) {
      const isUsernameValid = await validateUsername(username);

      await getbotInfo()
  
      if (!isUsernameValid) {

        toast.error('Invalid username. Please enter a valid username.');
        return;
      } 
    }

    setAnimate(false); // Disable animation temporarily
    setTimeout(() => {
      setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
      setAnimate(true); // Re-enable animation after setting the new step
    }, 300); // Adjust the duration to match your CSS transition duration
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const isStep1 = currentStep === 0;
  const isStep2 = currentStep === 1;
  const isStep3 = currentStep === 2;
  const isStep4 = currentStep === 3;

  const stepperHeightClass = isStep1 ? '!h-[200px]' : '';
  const stepperHeightClass2 = isStep2 ? '!h-[300px]' : '';
  const stepperHeightClass3 = isStep3 ? '!h-[305px]' : '';
  const stepperHeightClass4 = isStep4 ? '!h-[420px]' : '';

  const customWidth2 = isStep2 ? '575px' : '';
  const customWidth4 = isStep4 ? '600px' : '';


  return (
    <Popup
      open
      modal
      nested
      closeOnDocumentClick
      onClose={() => {
        setAnimate(false);
        onClose();
      }}
      overlayStyle={{
        background: 'rgba(0, 0, 0, 0.6)',
        transition: 'opacity 0.3s',
      }}
      contentStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center items horizontally
        justifyContent: 'center', // Center items vertically
        borderRadius: '8px',
        width: customWidth4 || customWidth2 || '500px',
        background: 'rgba(255, 255, 255)',
        padding: '20px',
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        transform: 'translateY(0)',
        opacity: animate ? 1 : 0,
        transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
      }}
    >
      <div className={`bg-white w-42 h-52  ${stepperHeightClass} ${stepperHeightClass2} ${stepperHeightClass3} ${stepperHeightClass4}`}>
        <div className="mb-4 text-center">
          <span className="text-4xl baloo text-gray-900 font-bold">{steps[currentStep].title}</span>
        </div>
        {isStep1 && (
          <div className="">
            <input
              type="text"
              name="username"
              placeholder="Roblox Username"
              required
              onChange={handleUsernameChange}
              value={username}
              maxLength={30}
              className="ml-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#196eb2] focus:border-[#196eb2] sm:text-sm"
            />
            <div class="flex items-center"> 
            <span className="text-gray-500 baloo text-sm text-center max-w-[500px] mt-2">
             Make sure this is the <span className="text-[#196eb2]">same Roblox username</span> for your account or you can't withdraw! Contact support if you are having any issues or problems!
           </span>
            </div>
          </div>
        )}

      {isStep2 && (
           <div className="flex flex-col items-center">
                   {botInfo.botFriendImage && (
             <img
               src={botInfo.botFriendImage} 
               alt="Bot Image"
               className="mt-2 object-cover mb-4"
             />
           )}

<p className="text-gray-500 baloo text-lg text-center max-w-[500px]">
             To trade with the bot, you need to make sure you know the bot{' '}
             <span className="text-[#196eb2]">{botInfo.username}</span>. Do not fall for any scammers or impersonators.
           </p>
         </div>
      )}

{isStep3 && (
           <div className="flex flex-col items-center">
                   {botInfo.botFriendImage && (
             <img
               src="./images/Level10.png"
               alt="Level 10 Image"
               className="mt-2 object-cover mb-4 w-24 h-24"
             />
           )}

<p className="text-gray-500 baloo text-lg text-center max-w-[500px]">
            You must be at least{' '}
             <span className="text-[#196eb2]">level 10</span> in order to trade with the bot. It will not work if you are not!
           </p>
         </div>
      )}

{isStep4 && (
           <div className="flex flex-col items-center">
                   {botInfo.botWaitingImage && (
             <img
               src={botInfo.botWaitingImage} 
               alt="Bot Image"
               className="mt-2 object-cover mb-4 w-[400px] h-48 ml-4"
             />
           )}

<p className="text-gray-500 baloo text-lg text-center max-w-[500px]">
            The bot: {' '}
             <span className="text-[#196eb2]">{botInfo.username}</span> is currently waiting for you in the server. Make sure the <span className="text-[#196eb2]">username matches</span> do not make any mistakes. You might get scammed if the username is wrong!
           </p>

  
         </div>
      )}
        <div className="mt-4 flex justify-between">
          <button
            onClick={() => {
              setAnimate(false);
              onClose();
            }}
            disabled={currentStep === 0}
            className="py-1 px-16 bg-[#cd4b5c] rounded-lg baloo text-white"
          >
                {isStep3 ? 'Nope' : 'Close'}
          </button>
          {isStep4 ? (
   <a
   onClick={createWithdrawRequest}
   href={botInfo.botPrivateServerUrl}
   target="_blank"
   rel="noopener noreferrer"
   className="py-2 px-12 rounded-lg baloo ml-4 bg-[#196eb2] text-white h-[40px] flex items-center"
 >
   Connect to server
   <span className="ml-2">
     <svg
       xmlns="http://www.w3.org/2000/svg"
       fill="#FFFFFF"
       viewBox="0 0 24 24"
       stroke-width="1.5"
       stroke="currentColor"
       className="w-6 h-6"
     >
       <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
     </svg>
   </span>
 </a>
) : (
  <button
    onClick={nextStep}
    disabled={currentStep === steps.length - 1 || (isStep1 && !isUsernameEntered)}
    className={`py-2 px-12 rounded-lg baloo ml-4 bg-[#196eb2] ${!isStep1 || isUsernameEntered ? 'text-white' : 'opacity-50 cursor-not-allowed text-white'}`}
  >
   {isStep3 ? 'Yes I am' : 'Continue'}
  </button>
)}

        </div>
      </div>
    </Popup>
  );
};

export default Stepper;
