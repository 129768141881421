import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Layout/Loader";
import axios from "axios";
import { server } from "../../server";

const AllProducts = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const { seller } = useSelector((state) => state.seller);
  const isLoading = false;

  const dispatch = useDispatch();

  const getAllWithdrawals = async () => {
    try {
      const response = await axios.post(`${server}/withdraw/get-all-withdrawals-shop`, { seller: seller });
      setWithdrawals(response.data.withdrawals);
    } catch (error) {
      console.error("Error fetching withdrawals:", error);
    }
  };
 
  useEffect(() => {
    getAllWithdrawals();
  }, [dispatch]);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      hide: true,
    },
    {
      field: "username",
      headerName: "Username",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "method",
      headerName: "Method",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "creation",
      headerName: "Created At",
      minWidth: 130,
      flex: 0.6,
    },
  ];

  const row = [];

  withdrawals &&
  withdrawals.forEach((item) => {
      row.push({
        id: item._id,
        username: item.username,
        method: item.type,
        amount: "US$ " + item.amount,
        status: item.status,
        creation: item.createdAt,
      });
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-gray-50">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            getRowId={(row) => row.id} 
          />
        </div>
      )}
    </>
  );
};

export default AllProducts;
