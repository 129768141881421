import React from "react";
import { AiOutlineFolderAdd, AiOutlineGift } from "react-icons/ai";
import {
  FiPackage,
  FiShoppingBag,
  FiCreditCard,
  FiLogOut,
  FiSettings,
} from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { backend_url, server } from "../../../server";
import axios from "axios";
import { toast } from "react-toastify";

const DashboardSideBar = ({ active }) => {
  const navigate = useNavigate();
  const logoutHandler = async () => {
    axios.get(`${server}/shop/logout`,{
      withCredentials: true,
    });
  localStorage.removeItem("cartItems");
  localStorage.removeItem("inventoryCartItems");
  navigate("/login")
  window.location.reload();
  };
  
  return (
    <div className="w-full h-[90vh] bg-white shadow-sm overflow-y-scroll sticky top-0 left-0 z-10">
      {/* single item */}
      <div className="w-full flex items-center p-4">
        <Link to="/dashboard" className="w-full flex items-center">
          <RxDashboard
            size={30}
            color={`${active === 1 ? "#196eb2" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[20px] font-[400] baloo ml-2 font-semibold  ${
              active === 1 ? "text-gray-900" : "text-gray-900"
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/dashboard-orders" className="w-full flex items-center">
          <FiShoppingBag
            size={30}
            color={`${active === 2 ? "#196eb2" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[20px] font-[400] baloo ml-2  font-semibold ${
              active === 1 ? "text-gray-900" : "text-gray-900"
            }`}
          >
            All Orders
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/dashboard-products" className="w-full flex items-center">
          <FiPackage size={30} color={`${active === 3 ? "#196eb2" : "#555"}`} />
          <h5
            className={`hidden 800px:block pl-2 text-[20px] font-[400] baloo ml-2 font-semibold  ${
              active === 1 ? "text-gray-900" : "text-gray-900"
            }`}
          >
            All Listings
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/dashboard-withdrawals" className="w-full flex items-center">
          <FiCreditCard
            size={30}
            color={`${active === 4 ? "#196eb2" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[20px] font-[400] baloo ml-2 font-semibold  ${
              active === 1 ? "text-gray-900" : "text-gray-900"
            }`}
          >
            All Withdrawals
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/dashboard-create-product"
          className="w-full flex items-center"
        >
          <AiOutlineFolderAdd
            size={30}
            color={`${active === 5 ? "#196eb2" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[20px] font-[400] baloo ml-2 font-semibold ${
              active === 1 ? "text-gray-900" : "text-gray-900"
            }`}
          >
            Inventory
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/settings" className="w-full flex items-center">
          <FiSettings
            size={30}
            color={`${active === 6 ? "#196eb2" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[20px] font-[400] baloo ml-2 font-semibold ${
              active === 1 ? "text-gray-900" : "text-gray-900"
            }`}
          >
            Settings
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4" onClick={logoutHandler}>
        <Link to="/settings" className="w-full flex items-center">
          <FiLogOut size={30} color="#555" />
          <h5
            className={`hidden 800px:block pl-2 text-[20px] font-[400] baloo ml-2 font-semibold ${
              active === 1 ? "text-gray-900" : "text-gray-900"
            }`}
          >
            Log Out
          </h5>
        </Link>
      </div>
    </div>
  );
};

export default DashboardSideBar;
