// add to cart
export const addToInventoryCart = (data) => async (dispatch, getState) => {
  dispatch({
    type: "addToInventoryCart",
    payload: data,
  });

  localStorage.setItem("inventoryCartItems", JSON.stringify(getState().inventoryCart.inventoryCart));
  return data;
};

// remove from cart
export const removeFromInventoryCart = (data) => async (dispatch, getState) => {
  dispatch({
    type: "removeFromInventoryCart",
    payload: data._id,
  });
  localStorage.setItem("inventoryCartItems", JSON.stringify(getState().inventoryCart.inventoryCart));
  return data;
};
