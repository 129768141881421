import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
} from "react-icons/ai";
import { BsTwitterX } from "react-icons/bs";
import { Link } from "react-router-dom";
import {
  footercompanyLinks,
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";

import Logo from './Logo'

const date = new Date

const Footer = () => {
  return (
    <div className="bg-[#edf0f2] text-gray-900">
      <div className="grid grid-cols-1 sm:gird-cols-3 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16 sm:text-center">
        <ul className="px-5 text-center sm:text-start flex sm:block flex-col items-center baloo">
           <Logo />
          <br />
          <p>Tradeblox is not affiliated, associated or partnered with Roblox or any of its trademarks. We are not sponsored by any companies, brands or assets shown on Tradeblox.</p>
          <div className="flex items-center mt-[15px]">
            <AiFillFacebook color="#393939" size={25} className="cursor-pointer" />
            <BsTwitterX
              color="#393939" 
              size={20}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
            <AiFillInstagram
              color="#393939"  
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
            <AiFillYoutube
              color="#393939" 
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
          </div>
        </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-semibold baloo text-[#196eb2]">Company</h1>
          {footerProductLinks.map((link,index) => (
            <li key={index}>
              <Link
                className="text-gray-900 hover:text-blue-400 duration-300
                   text-sm cursor-pointer leading-6 baloo"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-semibold baloo text-[#196eb2]">Shop</h1>
          {footercompanyLinks.map((link,index) => (
            <li key={index}>
              <Link
                className="text-gray-900 hover:text-blue-400 duration-300
                   text-sm cursor-pointer leading-6 baloo"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="text-center sm:text-start baloo">
          <h1 className="mb-1 font-semibold text-[#196eb2]">Support</h1>
          {footerSupportLinks.map((link,index) => (
            <li key={index}>
              <Link
                className="text-gray-900 hover:text-blue-400 duration-300
                   text-sm cursor-pointer leading-6 baloo"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10
         text-center pt-2 text-gray-900 text-sm pb-8 baloo"
      >
        <span>© {date.getFullYear()} Tradeblox.gg All rights reserved.</span>
        <div className="flex justify-center"><a className="mr-4" href="/terms">Terms ·</a>  <a  className="mr-4" href="/privacy"> Privacy Policy ·</a>  <a href="/aml"> Anti-money-laundering Policy</a></div>
        <div className="sm:block flex items-center justify-center w-full">
          <img
            src="https://hamart-shop.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffooter-payment.a37c49ac.png&w=640&q=75"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
