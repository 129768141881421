import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createProduct } from "../../redux/actions/product";
import { toast } from 'react-toastify';
import { backend_url } from '../../server'
import axios from 'axios';
import Popup from 'reactjs-popup';

const Stepper = ({ steps, data, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [username, setUsername] = useState("");
  const [itemPrice, setItemPrice] = useState(0);
  const [itemQuantity, setItemQuantity] = useState("");
  const [itemTags, setItemTags] = useState(1);
  const [isPriceEntered, setIsPriceEntered] = useState(true);
  const [isQuantityEntered, setIsQuantityEntered] = useState(false);
  const [isTagsEntered, setIsTagsEntered] = useState(false);
  const [botInfo, setBotInfo] = useState("")
  const { isSeller, seller } = useSelector((state) => state.seller);
  const [recommendedPrice, setRecommendedPrice] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setAnimate(true);
  }, []); 

  const handlePriceChange = (e) => {
    const enteredPrice = parseFloat(e.target.value);
  
    if (!isNaN(enteredPrice) && enteredPrice >= 0.35 && enteredPrice <= 1050) {
      setItemPrice(enteredPrice.toFixed(2).toString());
      setIsPriceEntered(true);
    } else {
      setIsPriceEntered(false);
    }
  };

  const handleQuantityChange = (e) => {
    const enteredQuantity = parseFloat(e.target.value);
  
    if (!isNaN(enteredQuantity) && enteredQuantity >= 1) {
      const limitedQuantity = Math.min(enteredQuantity, data.quantity);
  
      setItemQuantity(limitedQuantity.toString());
      setIsQuantityEntered(true);
    } else {
      setIsQuantityEntered(false);
    }
  };
  
  const handleTags = (e) => {
    const enteredTags = e.target.value;
    setItemTags(enteredTags);
    setIsTagsEntered(enteredTags.trim() !== '');
  };


  const validateUsername = async (robloxUsername) => {
    try {
      const response = await axios.post(`${backend_url}api/v2/user/validate-username`, { username: robloxUsername });
      const isValid = response.data.data;

      return isValid;
    } catch (error) {
      console.error('Error validating username:', error);

      // Return false in case of an error
      return false;
    }
  };


  const getRecommendedPrice = async (item) => {
    try {
      const response = await axios.get(`${backend_url}api/v2/product/get-recommended-price/?item=${item}`);

      setItemPrice(Math.round(response.data.data * 100) / 100);
      return response.data.data;
    } catch (error) {
      console.error('Error getting recommended price:', error);

      return "None";
    }
  }

  useEffect(() => {
    const fetchRecommendedPrice = async () => {
      const price = await getRecommendedPrice(data.name);
      setRecommendedPrice(Math.round(price * 100) / 100)
      console.log(price);
    };
  
    fetchRecommendedPrice();
  }, [data.name]);

  const getbotInfo = async () => {
    try {
      const response = await axios.get(`${backend_url}api/v2/bot/get-bots`);
      setBotInfo(response.data.bot); 
  
    } catch (error) {
      console.error(error);
      toast.error("Internal Server Error!");
      onClose();
    }
  };

  const nextStep = async () => {
    console.log(currentStep)
    if (isStep1) {
      const isUsernameValid = await validateUsername(username);

      await getbotInfo()
  
      if (!isUsernameValid) {

        toast.error('Invalid username. Please enter a valid username.');
        return;
      } 
    }

    setAnimate(false); // Disable animation temporarily
    setTimeout(() => {
      setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
      setAnimate(true); // Re-enable animation after setting the new step
    }, 300); // Adjust the duration to match your CSS transition duration
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const isStep1 = currentStep === 0;
  const isStep2 = currentStep === 1;
  const isStep3 = currentStep === 2;

  const stepperHeightClass = isStep2 ? '!h-72' : '';
  const stepperHeightClass2 = isStep3 ? '!h-[435px]' : '';

  const customWidth3 = isStep3 ? '700px' : '';


  const images = [data.thumbnail];
  const handleSubmit = (e) => {
    e.preventDefault();

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.append("images", image);
    });
    newForm.append("name", data.name);
    newForm.append("description", "My Murder Mystery 2 Item!");
    newForm.append("category", "mm2");
    newForm.append("tags", itemTags);
    newForm.append("originalPrice", itemPrice);
    newForm.append("quantity", itemQuantity);
    newForm.append("value", data.value);
    newForm.append("itemHash", data.itemHash);
    newForm.append("stock", 1);
    newForm.append("shopId", seller._id);

    dispatch(createProduct(newForm));
    onClose()
  };

  return (
    <Popup
      open
      modal
      nested
      closeOnDocumentClick
      onClose={() => {
        setAnimate(false);
        onClose();
      }}
      overlayStyle={{
        background: 'rgba(0, 0, 0, 0.6)',
        transition: 'opacity 0.3s',
      }}
      contentStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center items horizontally
        justifyContent: 'center', // Center items vertically
        borderRadius: '8px',
        width: customWidth3 || '500px',
        background: 'rgba(255, 255, 255)',
        padding: '20px',
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        transform: 'translateY(0)',
        opacity: animate ? 1 : 0,
        transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
      }}
    >
      <form onSubmit={handleSubmit}>
     <div className={`bg-white w-42 h-[535px] ${stepperHeightClass} ${stepperHeightClass2}`}>
        <div className="mb-4 text-center">
          <span className="text-4xl baloo text-gray-900 font-bold">Listing for {data.name}</span>
        </div>
        <div className='flex justify-center items-center'>
        <img className='w-[150px] h-[150px] mb-2' src={data.thumbnail} />
        </div>
        {isStep1 && (
          <div className="">
            <input
              type="number"
              name="itemPrice"
              placeholder="Item Price"
              required
              onChange={handlePriceChange}
              value={itemPrice}
              className="ml-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#196eb2] focus:border-[#196eb2] sm:text-sm"
            />
            <div className="flex justify-center items-center"> 
  <span className="text-gray-500 baloo text-sm max-w-56 mt-2 text-center">
    Minimum price: <span className="ml-1 text-[#196eb2]"> $0.35 </span> | Recommended price: <span className="ml-1 text-[#196eb2]">${recommendedPrice}</span>
  </span>
</div>

<input
              type="number "
              name="itemQuantity"
              placeholder="Item Quantity"
              onChange={handleQuantityChange}
              value={itemQuantity}
              required
              min={1}
              max={data.quantity}
              className="ml-2 mt-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#196eb2] focus:border-[#196eb2] sm:text-sm"
            />

<div className="flex justify-center items-center"> 
  <span className="text-gray-500 baloo text-sm max-w-56 mt-2 text-center">
  Amount of {data.name}'s you want to sell (same price<span className="text-[#196eb2] ml-1">per item quantity</span>) </span>
</div>

<input
              type="text"
              name="itemTags"
              placeholder="Item Tags"
              onChange={handleTags}
              value={itemTags}
              required
              minLength={3}
              maxLength={45}
              className="ml-2 mt-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#196eb2] focus:border-[#196eb2] sm:text-sm"
            />

<div className="flex justify-center items-center"> 
  <span className="text-gray-500 baloo text-sm max-w-56 mt-2 text-center">
   Tags so your product is sold faster (ex: <span className="text-[#196eb2] ml-1">knife, mm2, cheap</span>) </span>
</div>

          </div>
        )}

     
        <div className="mt-4 flex justify-between">
          <button
            onClick={() => {
              setAnimate(false);
              onClose();
            }}
            className="py-1 px-16 bg-[#cd4b5c] rounded-lg baloo text-white"
          >
            Close
          </button>
         
  <button
    type="submit"
    disabled={(!isPriceEntered && !isTagsEntered  && !isQuantityEntered)}
    className={`py-2 px-12 rounded-lg baloo ml-4 bg-[#196eb2] ${isPriceEntered || isTagsEntered || isQuantityEntered  ? 'text-white' : 'opacity-50 cursor-not-allowed text-white'}`}
  >
    Confirm
  </button>


        </div>
      </div>
      </form>
    </Popup>
  );
};

export default Stepper;
