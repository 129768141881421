import React from "react";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";
import AllWithdrawals from "../../components/Shop/AllWithdrawals";
import Footer from "../../components/Layout/Footer"

const ShopAllWithdrawals = () => {
  return (
        <div>
          <DashboardHeader />
          <div className="flex items-start justify-between w-full bg-gray-50">
            <div className="w-[80px] 800px:w-[330px]">
              <DashboardSideBar active={4} />
            </div>
            <AllWithdrawals />
          </div>
        </div>
  );
};

export default ShopAllWithdrawals;
