import React, { useEffect, useState, useRef  } from "react";
import ReactStepper from "../../Stepper/ListStepper";
import axios from "axios";
import { backend_url } from "../../../server";
import { motion } from "framer-motion"
import { IoIosAdd } from "react-icons/io";

const InventoryCard = ({ data, isEvent }) => {

  const [showStepper, setShowStepper] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const steps = [
    { title: "List an item" },
  ];

  const getColor = (value) => {
    if (value >= 0 && value <= 1.5) { // Common
      return "bg-[#40464d]"; 
    } else if (value > 1.51 && value <= 3) { // Uncommon
      return "bg-[#1a68e6]";
    } else if (value > 3.01 && value <= 7) { // Rare
      return "bg-[#33d300]"
    } else if (value > 7.01 && value <= 15) { // Ultra Rare
      return "bg-[#8a2be2]"
    }
   else if (value > 15.01 && value <= 25) { // Epic
    return "bg-[#e00202]"
   }
   else if (value > 25.01 && value <= 50) { // Ultra Epic
    return "bg-[#dd31f7]"
   }
   else if (value > 50.01 && value <= 150) { // Legendary
    return "bg-[#ed5d09]"
   }
   else if (value > 150.01 && value <= 600) { // Legendary
    return "bg-[#f7d631]"
   }
   else if (value > 600.01 && value <= 100000) { // Legendary
    return "bg-[#1c1c1b]"
   }
  };
  
  const colorClass = getColor(data.value);

  return (
    <>
       <div onClick={() => setShowStepper(true)}
        className={`w-full h-[250px] bg-[#edf0f2] rounded-xl shadow-sm p-3 relative cursor-pointer ${
          isHovered ? "hovered bg-gray-300" : ""
        }`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="flex justify-end"></div>
        <img
          src={data.thumbnail}
          alt=""
          className="w-full h-[170px] object-contain"
        />
        <div className="flex"></div>
        <h4 className="pb-1 font-[500] text-gray-700 baloo text-xl font-bold">
          {data.name.length > 40 ? data.name.slice(0, 40) + "..." : data.name}
        </h4>
        <p className="baloo text-lg font-bold">{data.quantity} pcs.</p>

        <div className={`h-[10px] w-[20px] h-[20px] ${colorClass} absolute bottom-0 right-0 mr-4 mb-2 rounded-md`}></div>

        {isHovered && (
          <button
            className="rounded-full bg-white text-black px-2 py-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            onClick={() => {
              // Handle the "Add" button click event
            }}
          >
            <IoIosAdd
              size={40}
              style={{ fontWeight: 'bold' }}
              className="cursor-pointer text-black"
            />
          </button>
        )}

{showStepper && (
          <ReactStepper steps={steps} data={data} onClose={() => setShowStepper(false)} />
        )}
      </div>
    </>
  );
};

export default InventoryCard;
