import React from 'react'
import Header from "../components/Layout/ProfileHeader";
import Footer from "../components/Layout/Footer";
import AdminSideBar from '../components/Admin/Layout/AdminSideBar'
import AllProducts from "../components/Admin/AllProducts";

const AdminDashboardProducts = () => {
  return (
    <div>
    <Header />
    <div className="w-full flex bg-gray-50">
      <div className="flex items-start justify-between w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <AdminSideBar active={5} />
        </div>
        <AllProducts />
      </div>
    </div>
    <Footer />
  </div>
  )
}

export default AdminDashboardProducts