import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import { IoMdSearch } from "react-icons/io";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import { getAllProducts } from "../redux/actions/product.js";

const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPriceRange, setSelectedPriceRange] = useState("");
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [selectedRarities, setSelectedRarities] = useState([]);

  const dispatch = useDispatch();

  const sortOptions = [
    { name: "Most Recent", href: "#", current: false },
    { name: "Price: Low to High", href: "#", current: false },
    { name: "Price: High to Low", href: "#", current: false },
  ];

  const [currentSortOption, setCurrentSortOption] = useState(sortOptions[0]);

  const sortProducts = (products, sortOption) => {
    const sortedProducts = [...products];

    switch (sortOption.name) {
      case "Most Recent":
        sortedProducts.sort();
        break;
      case "Price: Low to High":
        sortedProducts.sort((a, b) => a.originalPrice - b.originalPrice);
        break;
      case "Price: High to Low":
        sortedProducts.sort((a, b) => b.originalPrice - a.originalPrice);
        break;
      default:
        break;
    }

    return sortedProducts;
  };

  const handleRarityFilterChange = (range) => {
    setSelectedRarities((prevRarities) => {
      const existingRarityIndex = prevRarities.findIndex(
        (rarity) => JSON.stringify(rarity) === JSON.stringify(range)
      );

      if (existingRarityIndex !== -1) {
        // Remove the existing rarity if it exists
        const updatedRarities = [...prevRarities];
        updatedRarities.splice(existingRarityIndex, 1);
        return updatedRarities;
      } else {
        // Add the new rarity if it doesn't exist
        return [...prevRarities, range];
      }
    });
  };

  const filterByRarity = (productValue) => {
    return (
      selectedRarities.length === 0 ||
      selectedRarities.some(
        (selectedRange) =>
          productValue >= selectedRange[0] && productValue <= selectedRange[1]
      )
    );
  };

  useEffect(() => {
    const filteredProducts = allProducts
      .filter((product) => filterByRarity(product.value))
      .filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

    const sortedProducts = sortProducts(filteredProducts, currentSortOption);

    setData(sortedProducts);
  }, [
    searchTerm,
    categoryData,
    selectedPriceRange,
    currentSortOption,
    allProducts,
    selectedRarities,
  ]);

  const subCategories = [{ name: "Murdery Mystery 2", href: "#" }];
  const filters = [
    {
      id: "rarity",
      name: "Rarity",
      options: [
        { value: "common", label: "Common", checked: false, range: [0, 1.5] },
        {
          value: "uncommon",
          label: "Uncommon",
          checked: false,
          range: [1.51, 3],
        },
        { value: "rare", label: "Rare", range: [3.01, 7] },
        {
          value: "ultra-rare",
          label: "Ultra Rare",
          checked: false,
          range: [7.01, 15],
        },
        {
          value: "legendary",
          label: "Legendary",
          checked: false,
          range: [15.01, 50],
        },
        { value: "godly", label: "Godly", checked: false, range: [50.01, 150] },
        {
          value: "vintage",
          label: "Vintage",
          checked: false,
          range: [50.01, 600],
        },
        {
          value: "ancient",
          label: "Ancient",
          checked: false,
          range: [600.01, 100000],
        },
      ],
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={3} />

          <AnimatePresence exitBeforeEnter={false} mode="wait" initial={false}>
            <motion.div
              key="home-page"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="bg-white">
                <div>
                  {/* Mobile filter dialog */}
                  <Transition.Root show={mobileFiltersOpen} as={Fragment}>
                    <Dialog
                      as="div"
                      className="relative z-40 lg:hidden"
                      onClose={setMobileFiltersOpen}
                    >
                      <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                      </Transition.Child>

                      <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                          as={Fragment}
                          enter="transition ease-in-out duration-300 transform"
                          enterFrom="translate-x-full"
                          enterTo="translate-x-0"
                          leave="transition ease-in-out duration-300 transform"
                          leaveFrom="translate-x-0"
                          leaveTo="translate-x-full"
                        >
                          <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                            <div className="flex items-center justify-between px-4">
                              <h2 className="text-lg font-medium text-gray-900">
                                Filters
                              </h2>
                              <button
                                type="button"
                                className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                onClick={() => setMobileFiltersOpen(false)}
                              >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>

                            {/* Filters */}
                            <form className="mt-4 border-t border-gray-200">
                              <h3 className="sr-only">Categories</h3>
                              <ul
                                role="list"
                                className="px-2 py-3 font-medium text-blue-600"
                              >
                                {subCategories.map((category) => (
                                  <li key={category.name}>
                                    <a
                                      href={category.href}
                                      className="block px-2 py-3"
                                    >
                                      {category.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>

                              {filters.map((section) => (
                                <Disclosure
                                  as="div"
                                  key={section.id}
                                  className="border-t border-gray-200 px-4 py-6"
                                  defaultOpen={true}
                                >
                                  {({ open }) => (
                                    <>
                                      <h3 className="-mx-2 -my-3 flow-root">
                                        <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                          <span className="font-medium text-gray-900">
                                            {section.name}
                                          </span>
                                          <span className="ml-6 flex items-center">
                                            {open ? (
                                              <MinusIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <PlusIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            )}
                                          </span>
                                        </Disclosure.Button>
                                      </h3>
                                      <Disclosure.Panel className="pt-6">
                                        <div className="space-y-6">
                                          {section.options.map(
                                            (option, optionIdx) => (
                                              <div
                                                key={option.value}
                                                className="flex items-center"
                                              >
                                                <input
                                                  id={`filter-mobile-${section.id}-${optionIdx}`}
                                                  name={`${section.id}[]`}
                                                  defaultValue={option.value}
                                                  type="checkbox"
                                                  defaultChecked={
                                                    option.checked
                                                  }
                                                  onChange={() =>
                                                    handleRarityFilterChange(
                                                      option.range
                                                    )
                                                  }
                                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                />
                                                <label
                                                  htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                                  className="ml-3 min-w-0 flex-1 text-gray-500"
                                                >
                                                  {option.label}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              ))}
                            </form>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </Dialog>
                  </Transition.Root>

                  <main className="flex-1 min-w-0 px-4 sm:px-6 lg:px-8">
                    <div
                      className={`relative xl:w-[1550px] lg:w-[1450px] lg:w-[1100px] ${styles.normalFlex} mx-auto rounded-md mb-12`}
                      style={{
                        backgroundImage: "url('/images/snow-background.png')",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        backgroundSize: "cover",
                      }}
                    >
                      <div
                        className="absolute top-0 left-0 right-0 bottom-0 h-full filter blur-xs"
                        style={{
                          backdropFilter: "blur(3px)",
                          WebkitBackdropFilter: "blur(3px)", // For Safari
                        }}
                      />
                      <div
                        className={`${styles.section} w-2xl 800px:w-2xl flex flex-col justify-center items-start text-left text-white baloo my-auto rounded-md !h-[300px]`}
                      >
                        <h1
                          className={`text-[18px] text-left mt-6 leading-[1.2] 800px:text-[45px] font-bold capitalize font-[Poppins] drop-shadow-[0_1.1px_1.2px_rgba(0,0,0,0.35)]`}
                        >
                          Welcome To TradeBlox.GG <br />
                        </h1>

                        <div className="flex items-center">
                          <span className="pt-5 text-[16px] font-[Poppins] text-white drop-shadow-[0_1.1px_1.2px_rgba(0,0,0,0.35)]">
                            The first multi-game marketplace that allows you
                            buy/sell your Roblox items
                            <br />
                          </span>
                        </div>

                        <div className="flex">
                          <Link to="/sign-up" className="inline-block">
                            <div className="w-[150px] bg-[#5ec3e0] h-[50px] font-semibold my-3 flex items-center justify-center rounded-md baloo cursor-pointer mt-5 border border-white border-1 transform hover:scale-105 transition-transform duration-300 ease-in-out mr-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="w-4 h-4 text-[#fff]"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                                />
                              </svg>
                              <span className="text-[#fff] font-[Poppins] text-[14px] ml-2">
                                Shop Now
                              </span>
                            </div>
                          </Link>

                          <Link to="/shop-create" className="inline-block">
                            <div className="w-[150px] bg-[#196eb2] h-[50px] font-semibold my-3 flex items-center justify-center rounded-md baloo cursor-pointer mt-5 border border-white border-1 transform hover:scale-105 transition-transform duration-300 ease-in-out">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="w-4 h-4 text-[#fff]"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 6h.008v.008H6V6Z"
                                />
                              </svg>
                              <span className="text-[#fff] font-[Poppins] text-[14px] ml-2">
                                Sell Now
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-baseline justify-between border-b border-gray-200 pb-6">
                      <div className="flex items-center mt-2">
                        <div className="block relative">
                          <input
                            type="text"
                            placeholder="Search products..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="pl-8 pr-2 border border-gray-300 rounded-md p-2 focus:outline-none focus:border-blue-500 baloo lg:w-[300px] sm:w-[50px] sm:mr-4 text-md"
                          />
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <IoMdSearch className="text-gray-400" />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 ml-2">
                              Sort
                              <ChevronDownIcon
                                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                {sortOptions.map((option) => (
                                  <Menu.Item key={option.name}>
                                    {({ active }) => (
                                      <a
                                        href={option.href}
                                        className={classNames(
                                          option.current
                                            ? "font-medium text-gray-900"
                                            : "text-gray-500",
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm"
                                        )}
                                        onClick={() =>
                                          setCurrentSortOption(option)
                                        }
                                      >
                                        {option.name}
                                      </a>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>

                        <button
                          type="button"
                          className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
                        >
                          <span className="sr-only">View grid</span>
                          <Squares2X2Icon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                        <button
                          type="button"
                          className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                          onClick={() => setMobileFiltersOpen(true)}
                        >
                          <span className="sr-only">Filters</span>
                          <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <section
                      aria-labelledby="products-heading"
                      className="pb-24 pt-2"
                    >
                      <div className="grid sm:grid-cols-1 lg:grid-cols-4 gap-4">
                        {/* Filters */}
                        <form className="hidden lg:block max-w-sm">
                          <h3 className="sr-only">Categories</h3>
                          <div className="flex"></div>
                          <ul
                            role="list"
                            className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-[#196eb2] flex mt-4"
                          >
                            {subCategories.map((category) => (
                              <li key={category.name}>
                                <a href={category.href}>{category.name}</a>
                              </li>
                            ))}
                          </ul>

                          {filters.map((section) => (
                            <Disclosure
                              as="div"
                              key={section.id}
                              className="border-b border-gray-200 py-6"
                              defaultOpen={true}
                            >
                              {({ open }) => (
                                <>
                                  <h3 className="-my-3 flow-root">
                                    <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                                      <span className="font-medium text-[#5ec3e0]">
                                        {section.name}
                                      </span>
                                      <span className="ml-6 flex items-center">
                                        {open ? (
                                          <MinusIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <PlusIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </span>
                                    </Disclosure.Button>
                                  </h3>
                                  <Disclosure.Panel className="pt-6">
                                    <div className="space-y-4">
                                      {section.options.map(
                                        (option, optionIdx) => (
                                          <div
                                            key={option.value}
                                            className="flex items-center"
                                          >
                                            <input
                                              id={`filter-${section.id}-${optionIdx}`}
                                              name={`${section.id}[]`}
                                              defaultValue={option.value}
                                              type="checkbox"
                                              defaultChecked={option.checked}
                                              onChange={() =>
                                                handleRarityFilterChange(
                                                  option.range
                                                )
                                              }
                                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                            <label
                                              htmlFor={`filter-${section.id}-${optionIdx}`}
                                              className="ml-3 text-sm text-gray-600"
                                            >
                                              {option.label}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          ))}
                        </form>

                        {/* Product grid */}
                        <div className="lg:col-span-3">
                          <div className="grid sm:grid-cols-1 gap-4 lg:grid-cols-4">
                            <AnimatePresence>
                              {data &&
                                data.map((i, index) => (
                                  <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 20 }}
                                    transition={{
                                      duration: 0.5,
                                      delay: index * 0.1,
                                    }}
                                  >
                                    <ProductCard data={i} />
                                  </motion.div>
                                ))}
                            </AnimatePresence>
                          </div>
                          {data && data.length === 0 ? (
                            <h1 className="text-center w-full pb-[500px] text-[20px]">
                              No products Found!
                            </h1>
                          ) : null}
                        </div>
                      </div>
                    </section>
                  </main>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
          <Footer />
        </div>
      )}
    </>
  );
};

export default ProductsPage;
