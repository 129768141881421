import { React, useState, useMemo } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { RxAvatar } from "react-icons/rx";
import UnsignedHeader from "../Layout/UnsignedHeader"
import Footer from "../Layout/Footer"
import Select from 'react-select';
import countryList from 'react-select-country-list';
import ReCAPTCHA from 'react-google-recaptcha';

const ShopCreate = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name,setName] = useState("");
  const [robloxUsername,setRobloxUsername] = useState("");
  const [password, setPassword] = useState("");
  const [country,setCountry] = useState(null);
  const [visible, setVisible] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const options = useMemo(() => countryList().getData(), []);

  const handleChange = (selectedOption) => {
    setCountry(selectedOption);
  };
     
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      toast.error("Please complete the captcha verification.");
      return;
    }

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const newForm = new FormData();

   // newForm.append("file", avatar);
    newForm.append("name", name);
    newForm.append("email", email);
    newForm.append("password", password);
    newForm.append("country", country.label);
    newForm.append("robloxUsername", robloxUsername);
    axios
      .post(`${server}/shop/create-shop`, newForm, config)
      .then((res) => {
        toast.success(res.data.message);
        setName("");
        setEmail("");
        setPassword("");
        setCountry("");
        setRobloxUsername();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };


  return ( 
  <div>
    <UnsignedHeader />
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-2 sm:px-6 lg:px-6">   
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-2 mb-2 baloo-bold text-center fon-bold text-3xl text-gray-700">
        Create a sellers account
        </h2>
      </div>
      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>

          <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 baloo"
              >
                Store Name
              </label>
              <div className="mt-1">
                <input
                  type="name"
                  name="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm baloo"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 baloo"
              >
                Roblox Username
              </label>
              <div className="mt-1">
                <input
                  type="name"
                  name="name"
                  required
                  value={robloxUsername}
                  onChange={(e) => setRobloxUsername(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm baloo"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 baloo"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm baloo"
                />
              </div>
            </div>
            
            <div>
  <label
    htmlFor="email"
    className="block text-sm font-medium text-gray-700"
  >
    Country
  </label>
  <div className="mt-1">
  <Select
            options={options}
            value={country}
            onChange={handleChange}
            className="baloo"
            placeholder="Select your country"
        />
  </div>
</div>


            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 baloo"
              >
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  maxLength={64}
                  minLength={8}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm baloo"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>

            <div>
                <ReCAPTCHA
                  sitekey="6LfJyuUoAAAAAPns_-PFu43LTpBvXlSBU7Lf8XVo"
                  onChange={(value) => setRecaptchaValue(value)}
                  className="flex justify-center"
                />
             </div>

             <p className="baloo text-[14px] text-gray-700 mt-2">By registering and verifying you agree to all of our <a className="underline" href="/terms">Terms of Service</a>, <a className="underline" href="/privacy">Privacy Policy</a> and our <a className="underline" href="/aml">Anti-money-laundering</a> policy</p>

            <div>
              <button
                type="submit" 
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-[#81d1eb] hover:bg-[#5ec3e0] baloo"
              >
                Submit
              </button>
            </div>
            <div className={`${styles.normalFlex} w-full`}>
              <h4>Already have an account?</h4>
              <Link to="/shop-login" className="pl-2 baloo text-gray-700 underline">
                Log In
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Footer />
 </div>
  );
};

export default ShopCreate;
