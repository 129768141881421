import { createReducer } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  inventoryCart: localStorage.getItem("inventoryCartItems")
    ? JSON.parse(localStorage.getItem("inventoryCartItems"))
    : [],
};

export const inventoryCartReducer = createReducer(initialState, {
  addToInventoryCart: (state, action) => {
    const item = action.payload;
    const isItemExist = state.inventoryCart.find((i) => i._id === item._id);

    if (state.inventoryCart.length >= 4) {
      toast.error("Cannot add more than 4 items to the cart!");
      return state; 
    }

    if (isItemExist) {
      return {
        ...state,
        inventoryCart: state.inventoryCart.map((i) => (i._id === isItemExist._id ? item : i)),
      };
    } else {
      toast.success("Item successfully added to cart!")
      return {
        ...state,
        inventoryCart: [...state.inventoryCart, item],
      };
    }
  },

  removeFromInventoryCart: (state, action) => {
    return {
      ...state,
      inventoryCart: state.inventoryCart.filter((i) => i._id !== action.payload),
    };
  },
});
