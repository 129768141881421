import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import {
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { backend_url } from "../../server";
import Logo from './Logo'

const Header = ({ activeHeading }) => {
  const { isSeller } = useSelector((state) => state.seller);
  const { allProducts } = useSelector((state) => state.products);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);

  const [active, setActive] = useState(false);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setSearchData(filteredProducts);
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  return (
    <div className={`${styles.section}`}>
      <div className="hidden 800px:h-[50px] 800px:my-[20px] 800px:flex items-center justify-between">
        <div>
          <Link to="/">
           <Logo />
          </Link>
        </div>

        <div className="flex items-center justify-between">
          <Link to="/login">
          <div className="text-gray-700 text-md baloo font-semibold my-3 cursor-pointer mr-4">
            Log In
          </div>
          </Link>


          <div className="w-[85px] bg-[#196eb2] hover:bg-[#196eb2] h-[30px] my-3 flex items-center justify-center rounded-full cursor-pointer">
            <Link to={`${isSeller ? "/dashboard" : "/sign-up"}`}>
              <h1 className="text-white text-md flex items-center baloo font-bold">
                Sign up
              </h1>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
