import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { backend_url, server } from "../../server";
import axios from "axios";
import Popup from "reactjs-popup";

const Stepper = ({ steps, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [animate, setAnimate] = useState(false);
  const { isSeller, seller } = useSelector((state) => state.seller);
  const [botInfo, setBotInfo] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(10);
  const [withdrawUsername, setWithdrawUsername] = useState("");

  useEffect(() => {
    setAnimate(true);
    setSelectedMethod({ name: "PayPal", image: "/images/paypal-logo.png" });
  }, []); 

  const nextStep = async () => {
    setAnimate(false); // Disable animation temporarily
    setTimeout(() => {
   if (isStep1 && selectedMethod.name === "PayPal") {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
        setCurrentStep(1);
      } else if (isStep1 && selectedMethod.name === "Cash App") {
        setCurrentStep(2);
      }  
      setAnimate(true); 
    }, 300);
  };

  const isStep1 = currentStep === 0;
  const isStep2 = currentStep === 1;
  const isStep3 = currentStep === 2;

  const stepperHeightClass = isStep2 ? "!h-[300px]" : "";
  const stepperHeightClass1 = isStep1 ? "!h-[525px]" : "";
  const stepperHeightClass2 = isStep3 ? "!h-[300px]" : "";

  const customWidth1 = isStep1 ? "600px" : "";
  const customWidth2 = isStep2 || isStep3 ? "600px" : "";

  const withdrawalMethods = [
    { name: "PayPal", image: "/images/paypal-logo.png" },
    { name: "Cash App", image: "/images/cashapp-logo.png" },
  ];
  
  const selectPaymentMethod = (method) => {
    if (isStep1) {
      setSelectedMethod((prevMethod) => {
        const newMethod = prevMethod === method ? null : method;
       // console.log(newMethod); 
        return newMethod;
      });
    }
  };
  

  const error = () => {
    toast.error("You need at least $10 to withdraw!");
  };

  const withdrawHandler = async () => {
    if (withdrawAmount < 10 || withdrawAmount > availableBalance) {
      toast.error("You can't withdraw this amount!");
    } else {
      const amount = withdrawAmount;
      const username = withdrawUsername;
      const type = selectedMethod.name;
  
      try {
        const response = await axios.post(
          `${server}/withdraw/create-withdraw-request`,
          { amount, username, type },
          { withCredentials: true }
        );
  
        if (response.data.success) {
          toast.success("Your withdrawal request was successfully sent!");
        } else {
          toast.error("There was an error, please try again later!");
        }
      } catch (error) {
        toast.error(`You already have a pending ${selectedMethod.name} withdrawal request!`);
      }
    }
  };

  const availableBalance = seller?.availableBalance.toFixed(2);


  return (
    <Popup
      open
      modal
      nested
      closeOnDocumentClick
      onClose={() => {
        setAnimate(false);
        setSelectedMethod(null);
        onClose();
      }}
      overlayStyle={{
        background: "rgba(0, 0, 0, 0.6)",
        transition: "opacity 0.3s",
      }}
      contentStyle={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px",
        width: customWidth1 || customWidth2 || "500px",
        background: "rgba(255, 255, 255)",
        padding: "20px",
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
        transform: "translateY(0)",
        opacity: animate ? 1 : 0,
        transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
      }}
    >
      <div
        className={`bg-white w-42 h-52 ${stepperHeightClass} ${stepperHeightClass2} ${stepperHeightClass1}`}
      >
        <div className="mb-4 text-center">
          <span className="text-4xl baloo text-gray-900 font-bold">
            {steps[currentStep].title}
          </span>
        </div>
        {isStep1 && (
          <div className="">
      {withdrawalMethods.map((method, index) => (
 <div
 key={index}
 className={`relative p-4 rounded-md border-[2px] cursor-pointer mb-4 bg-gray-100 ${
   selectedMethod && selectedMethod.name === method.name ? "bg-gray-100 border-[#196eb2] border-[2px]" : "bg-gray-100 border-transparent"
 }`}
 onClick={() => isStep1 && selectPaymentMethod(method)}
>
    <img
      src={method.image}
      alt={method.name}
      className="w-auto h-24 mx-auto mb-2"
    />
    <span
      className={`text-gray-700 text-center text-lg baloo font-semibold ${
        selectedMethod === method ? "text-purple-500" : ""
      }`}
    >
      {method.name}
    </span>
    {selectedMethod === method && (
      <div className="absolute top-0 bottom-0 left-0 right-0 border-2 border-purple-500 rounded-md opacity-50"></div>
    )}
  </div>
))}


<div className="flex items-center justify-center">
  <span className="text-gray-500 text-center baloo text-sm max-w-[500px] mt-2">
    Please note that balance withdrawals may take up to{" "}
    <span className="text-[#196eb2]">1 to 2 days</span> to be completed.
    Contact support if you are having any technical issues or problems!
  </span>
</div>

          </div>
        )}

{isStep2 && (
          <div className="flex flex-col items-center">
            <p className="text-gray-500 baloo text-md text-center max-w-[500px] mb-2">
            You currently have <span className="text-black baloo font-bold">${availableBalance} </span>available to withdraw (<span class="text-[#196eb2]">minimum $10</span>). Please note that withdrawals may take up to <span class="text-[#196eb2]">1 to 2 days</span> to be processed, contact support if it takes longer than this!
            </p>

            <input
              type="number"
              name="amount"
              placeholder="Withdrawal Amount"
              required
              onChange={(e) => setWithdrawAmount(e.target.value)}
              value={withdrawAmount}
              min={10}
              max={5000}
              className="ml-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#196eb2] focus:border-[#196eb2] sm:text-sm mb-2"
            />

<input
              type="text"
              name="email"
              placeholder="PayPal Email"
              required
              onChange={(e) => setWithdrawUsername(e.target.value)}
              value={withdrawUsername}
              className="ml-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#196eb2] focus:border-[#196eb2] sm:text-sm mb-2"
            />
          </div>
        )}

        {isStep3 && (
          <div className="flex flex-col items-center">
          <p className="text-gray-500 baloo text-md text-center max-w-[500px] mb-2">
          You currently have <span className="text-black baloo font-bold">${availableBalance} </span>available to withdraw (<span class="text-[#196eb2]">minimum $10</span>). Please note that withdrawals may take up to <span class="text-[#196eb2]">1 to 2 days</span> to be processed, contact support if it takes longer than this!
          </p>

          <input
            type="number"
            name="amount"
            placeholder="Withdrawal Amount"
            required
            onChange={(e) => setWithdrawAmount(e.target.value)}
            value={withdrawAmount}
            min={10}
            max={5000}
            className="ml-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#196eb2] focus:border-[#196eb2] sm:text-sm mb-2"
          />

<input
            type="text"
            name="email"
            placeholder="Cash App Username"
            required
            onChange={(e) => setWithdrawUsername(e.target.value)}
            value={withdrawUsername}
            className="ml-2 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#196eb2] focus:border-[#196eb2] sm:text-sm mb-2"
          />
        </div>
        )}

<div className="mt-4 flex justify-between">
          <button
            onClick={() => {
              setAnimate(false);
              onClose();
            }}
            className="py-1 px-16 bg-[#cd4b5c] rounded-lg baloo text-white"
          >
            Close
          </button>

          {isStep2 || isStep3 ? (
         <button
         onClick={withdrawHandler}
         className={`py-2 px-12 rounded-lg baloo ml-4 bg-[#196eb2] ${
           !isStep1 || selectedMethod
             ? "text-white"
             : "opacity-50 text-white"
         }`}
       >
         Withdraw
       </button>
) : (
  <button
  onClick={nextStep}
  className={`py-2 px-12 rounded-lg baloo ml-4 bg-[#196eb2] ${
    !isStep1 || selectedMethod
      ? "text-white"
      : "opacity-50 text-white"
  }`}
>
  Continue
</button>
)}
        </div>
      </div>
    </Popup>
  );
};
export default Stepper;
