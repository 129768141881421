import React from 'react';

const Logo = () => {
  return <div>
  <img
              src="/images/logo3.png"
              alt="logo"
              className="w-38 h-16"
        />
  </div>;
};

export default Logo;