import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

export default function LineChart({ data }) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const myChartRef = chartRef.current.getContext('2d');

    chartInstance.current = new Chart(myChartRef, {
      type: 'line',
      data: {
        labels: data.labels || [],
        datasets: [
          {
            label: 'Value',
            data: data.chartData || [],
            fill: data.fill || false,
            borderColor: data.borderColor || '#196eb2',
            borderWidth: data.borderWidth || 2,
          },
        ],
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return (
    <div>
      <canvas ref={chartRef} style={{ width: '650px', height: '175px' }} />
    </div>
  );
}
