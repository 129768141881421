import React, { useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from "react-icons/ai";
import { backend_url } from "../../../server";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ReactStepper from "../../Stepper/StatsStepper";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { useEffect } from "react";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";

const ProductCard = ({ data,isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [showStepper, setShowStepper] = useState(false);
  const { isSeller, seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();

  const steps = [
    { title: "Item Statistics" },
  ];

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
  
    if (isSeller) {
      toast.error("You must have a buyer account to purchase this item!");
    } else if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
      }
    }
  };

  const getColor = (value) => {
    if (value >= 0 && value <= 1.5) { // Common
      return "bg-[#40464d]"; 
    } else if (value > 1.51 && value <= 3) { // Uncommon
      return "bg-[#1a68e6]";
    } else if (value > 3.01 && value <= 7) { // Rare
      return "bg-[#33d300]"
    } else if (value > 7.01 && value <= 15) { // Ultra Rare
      return "bg-[#8a2be2]"
    }
   else if (value > 15.01 && value <= 25) { // Epic
    return "bg-[#e00202]"
   }
   else if (value > 25.01 && value <= 50) { // Ultra Epic
    return "bg-[#dd31f7]"
   }
   else if (value > 50.01 && value <= 150) { // Legendary
    return "bg-[#ed5d09]"
   }
   else if (value > 150.01 && value <= 600) { // Legendary
    return "bg-[#f7d631]"
   }
   else if (value > 600.01 && value <= 100000) { // Legendary
    return "bg-[#1c1c1b]"
   }
  };

  const colorClass = getColor(data.value);
  
  return (
    <>
      <div className="w-full h-[285px] bg-[#edf0f2] rounded-xl shadow-sm p-3 relative cursor-pointer">
        <div className="flex justify-end"></div>
          <img
            src={`${data.images[0]}`}
            alt=""
            className="w-full h-[170px] object-contain"
          />
          <h4 className="pb-1.5 font-[500] baloo text-lg font-bold">
            {data.name.length > 40 ? data.name.slice(0, 40) + "..." : data.name}
          </h4>

          <div className="flex">
            <p className="baloo text-gray-600 text-md">{data.shop.robloxUsername} | {data.shop.name}</p>
        </div>


          <div className="py-2 flex items-center justify-between">
            <div className="flex">
              <h4 className={"text-xl text-gray-800 baloo-bold"}>
                ${data.originalPrice } 
              </h4>
            </div>
          </div>

          <div className={`h-[10px] w-[20px] h-[20px] ${colorClass} absolute bottom-0 right-0 mr-4 mb-2 rounded-md`}></div>

        {/* side options */}
        <div>
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Add to wishlist"
            />
          )}
          <AiOutlineEye
            size={22}
            className="cursor-pointer absolute right-2 top-14"
            onClick={() => setShowStepper(true)}
            color="#333"
            title="Quick view"
          />
          <AiOutlineShoppingCart
            size={25}
            className="cursor-pointer absolute right-2 top-24"
            onClick={() => addToCartHandler(data._id)}
            color="#444"
            title="Add to cart"
          />
                {showStepper && (
          <ReactStepper steps={steps} data={data} onClose={() => setShowStepper(false)} />
        )}
        </div>
      </div>

    </>
  );
};

export default ProductCard;
