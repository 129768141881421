import React from 'react';
import Header from '../components/Layout/UnsignedHeader'
import Footer from '../components/Layout/Footer'

const NotFound = () => {
  return (
    <div>
      <Header />
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center">
        <h1 className="text-5xl font-bold text-gray-800 baloo">404 - Not Found</h1>
        <p className="mt-4 text-lg text-gray-600 baloo">The page you are looking for doesn't exist.</p>
      </div>
    </div>
     <Footer />
    </div>
  );
};

export default NotFound;
