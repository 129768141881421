import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  inventoryData: [],
  error: null,
};

export const inventoryReducer = createReducer(initialState, {
  fetchInventoryRequest: (state) => {
    state.isLoading = true;
  },
  fetchInventorySuccess: (state, action) => {
    state.isLoading = false;
    state.inventoryData = action.payload;
  },
  fetchInventoryFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
